const LoginRoutes = [
    {
        path: '/login',
        name: 'login',
        component: () => import("@/views/login/LoginView.vue")
    },
    {
        path: '/register',
        name: 'register',
        component: () => import("@/views/login/RegisterView.vue")
    },
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        component: () => import("@/views/login/ForgetPasswordView.vue")
    }
]

export default LoginRoutes