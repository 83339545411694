import { createRouter, createWebHistory } from 'vue-router'
import toolRoutes from "@/router/toolRoutes";
import loginRoutes from "@/router/loginRoutes";
import notFoundRoutes from "@/router/404";
import rootRoutes from "@/router/rootRoutes";
import store from "@/store";
import {GetAuthorization} from "@/request";
import workOrderRoutes from "@/router/workOrderRoutes";

const indexRoutes = [
  {
    path: '/',
    name: 'index',
    redirect: '/tool'
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import("@/views/userCenter/UserCenterRoot.vue"),
    meta: {
      require_authentication: true
    }
  }
]

// noinspection JSCheckFunctionSignatures
const routes = indexRoutes.concat(toolRoutes, workOrderRoutes, loginRoutes, rootRoutes, notFoundRoutes)

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // noinspection JSUnresolvedReference
  if (to.meta.require_authentication) {
    if (store.getters.getIsLogin || GetAuthorization()) {
      next();
    }
    else {
      next({
        path: '/login',
      })
    }
  }
  else {
    next();
  }
})

export default router
