const workOrderRoutes = [
    {
        path: '/workOrder',
        name: 'workOrder',
        children: [
            {
                path: '',
                component: () => import("@/views/workOrder/WorkOrderListView.vue"),
            },
            {
                path: ':workOrderId',
                component: () => import("@/views/workOrder/WorkOrderView.vue"),
                props: true
            }
        ],
        meta: {
            require_authentication: true
        }
    },
]

export default workOrderRoutes;