const toolRoutes = [
    {
        path: '/tool',
        component: () => import("@/views/tools/RootComponent.vue"),
        children: [
            {
                path: '',
                component: () => import("@/views/tools/IndexView.vue")
            },
            {
                path: 'cloudClipBoard',
                name: 'cloudClipBoard',
                component: () => import("@/views/tools/cloudClipboard/UploadClipBoardView.vue"),
                meta: {
                    require_authentication: true
                }
            },
            {
                path: 'physicalExperimentCalculator',
                children: [
                    {
                        path: 'millikanOilDrop',
                        name: 'millikanOilDrop',
                        component: () => import("@/views/tools/physicalExperimentCalculator/MillikanOilDropView.vue")
                    },
                    {
                        path: 'spectraMeter',
                        name: 'spectraMeter',
                        component: () => import("@/views/tools/physicalExperimentCalculator/SpectraMeterView.vue")
                    },
                    {
                        path: 'specificElectronCharge',
                        name: 'specificElectronCharge',
                        component: () => import("@/views/tools/physicalExperimentCalculator/SpecificElectronChargeView.vue")
                    },
                    {
                        path: 'planckConstant',
                        name: 'planckConstant',
                        component: () => import("@/views/tools/physicalExperimentCalculator/PlanckConstantView.vue")
                    },
                    {
                        path: 'metalWorkFunction',
                        name: 'metalWorkFunction',
                        component: () => import("@/views/tools/physicalExperimentCalculator/MetalWorkFunctionView.vue")
                    },
                ]
            },
        ]
    },

    {
        path: '/tool/cloudClipBoard/:uuid',
        name: 'getCloudClipBoard',
        component: () => import("@/views/tools/cloudClipboard/LoadClipBoardView.vue"),
        props: true
    }
]

export default toolRoutes