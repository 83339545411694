<!--suppress VueUnrecognizedDirective -->
<script setup>
import {ref, unref} from "vue";
import {Message} from "@element-plus/icons-vue";
import {GetAuthorization} from "@/request";
import {ElNotification} from "element-plus";
import router from "@/router";
import {ReadMessage} from "@/request/message";

const buttonRef = ref();
const popoverRef = ref();
const onClickOutside = () => {
  unref(popoverRef).popperRef?.delayHide?.();
}

const listData = ref([]);

const webSocket = new WebSocket(process.env['VUE_APP_WEBSOCKET_API_HOST'] + "/webSocket/home?Authorization=" + GetAuthorization());
webSocket.onopen = () => {
};
webSocket.onmessage = event => {
  let data = JSON.parse(event.data);
  switch (data["type"]) {
    case "handshake":
      onHandshake(data["data"]);
      break;
    case "message":
      onMessage(data["data"]);
      break;
  }
}
webSocket.onerror = () => {
  ElNotification({type: "error", title: "与服务器的连接发生错误", message: "请刷新后重试"});
}
webSocket.onclose = () => {
  ElNotification({type: "warning", title: "与服务器的连接已断开", message: "请刷新后重试"});
};

const timestampToTime = timestamp => {
  timestamp = timestamp ? timestamp : null;
  let date = new Date(timestamp);
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

const onHandshake = data => {
  listData.value = data;
  for (let i = 0; i < listData.value.length; i++) {
    listData.value[i]["createTime"] = timestampToTime(listData.value[i]["createTime"]);
  }
}

const onMessage = data => {
  data.createTime = timestampToTime(data.createTime);
  listData.value.unshift(data);
}

const ClickMessage = (messageId, url) => {
  if (url) {
    doReadMessage(messageId);
    router.push(url);
  }
}

const doReadMessage = async messageId => {
  const data = await ReadMessage(messageId);
  if (data["status"] === "success") {
    for (let i = 0; i < listData.value.length; i++) {
      if (listData.value[i]["id"] === messageId) {
        listData.value.splice(i, 1);
      }
    }
  }
}
</script>

<template>
  <el-badge :value="listData.length" :hidden="listData.length === 0" class="item">
    <el-button ref="buttonRef" v-click-outside="onClickOutside">
      <el-icon>
        <Message/>
      </el-icon>
    </el-button>

    <el-popover ref="popoverRef" :virtual-ref="buttonRef" trigger="click" title="消息列表" virtual-triggering
                width="30em">
      <el-scrollbar max-height="30em">
        <el-empty v-if="listData.length === 0" description="暂无新消息" />
        <el-card shadow="hover" v-for="item in listData" :key="item.id" :body-style="{padding: '10px'}"
                 style="margin: 10px 0 10px 0">
          <el-row :gutter="20">
            <el-col :span="20" @click="ClickMessage(item.id, item.targetUrl)" style="cursor: pointer">
              <div style="font-size: medium; font-weight: bold">{{ item.title }}</div>
              <div>{{ item.content }}</div>
            </el-col>
            <el-col :span="4">
              <el-button type="info" plain size="small" style="margin-top: 0.5em; margin-right: 0.5em"
                         @click="doReadMessage(item.id)">
                已读
              </el-button>
            </el-col>
          </el-row>
          <div style="color: darkgray; font-size: smaller; float: right">{{ item.createTime }}</div>
        </el-card>
      </el-scrollbar>
    </el-popover>
  </el-badge>

</template>

<style scoped>

</style>