const rootRoutes = [
    {
        path: '/root',
        component: () => import("@/views/root/RootComponent.vue"),
        children: [
            {
                path: '',
                redirect: '/root/board'
            },
            {
                path: 'board',
                component: () => import("@/views/root/BoardView.vue")
            },
            {
                path: 'pageManagement',
                children: [
                    {
                        path: 'updateLog',
                        component: () => import("@/views/root/pageManagement/UpdateLog.vue")
                    }
                ]
            },
            {
                path: 'messageManagement',
                children: [
                    {
                        path: 'list',
                        component: () => import("@/views/root/messageManagement/MessageListView.vue")
                    },
                    {
                        path: 'sendMessage',
                        component: () => import("@/views/root/messageManagement/SendMessageView.vue")
                    }
                ]
            },
            {
                path: 'workOrderManagement',
                children: [
                    {
                        path: 'list',
                        component: () => import("@/views/root/workOrderManagement/OrderListView.vue")
                    }
                ]
            }
        ],
        meta: {
            require_authentication: true
        }
    }
]

export default rootRoutes