<script setup>

import {Box, DocumentCopy, House, Promotion, Setting, SwitchButton, User} from "@element-plus/icons-vue";
import axiosService from "@/request";
import store from "@/store";
import {useStorage} from "@vueuse/core";
import router from "@/router";
import {computed} from "vue";
import MessageTip from "@/components/MessageTip.vue";

const authorization = useStorage("Authorization", "");

const isLogin = computed(() => {
  return store.getters.getIsLogin;
});
const userType = computed(() => {
  return store.getters.getUserType;
})

const GetUidAndUsername = () => {
  if (authorization.value === "" && store.getters.getAuthorization === "") {
    return;
  }
  axiosService({
    url: "/user/get/UidAndUsername",
    method: "get"
  }).then(data => {
    store.commit("setUid", data["uid"]);
    store.commit("setUsername", data["username"]);
    store.commit("setUserType", data["userType"]);
    store.commit("setIsLogin", true);
  })
}

GetUidAndUsername();

const Logout = () => {
  authorization.value = "";
  store.commit("setIsLogin", false);
  store.commit("setAuthorization", "");
  router.push("/login")
}

</script>

<template>
  <el-container>
    <el-header>
      <el-affix :offset="0">
        <el-menu
            :default-active="$route.path"
            class="el-menu-demo"
            mode="horizontal"
            :ellipsis="false"
            router
            popper-effect="light"
        >
          <el-menu-item>
            <el-container>
              <img src="@/assets/favicon.png" alt="" style="height: 3.5rem"/>
              <div style="font-size: x-large; font-weight: bolder; margin-left: 5px">AC自动集</div>
            </el-container>
          </el-menu-item>
          <el-menu-item index="/"><el-icon><Box /></el-icon>工具集</el-menu-item>
          <el-menu-item index="/workOrder"><el-icon><DocumentCopy /></el-icon>工单系统</el-menu-item>
          <div class="flex-grow"/>
          <MessageTip v-if="isLogin" style="margin-top: 0.75em; margin-right: 1em"/>
          <el-sub-menu v-if="isLogin">
            <template #title><el-icon><User /></el-icon>
              {{
                store.getters.getUsername
              }}
            </template>
            <el-menu-item index="/root" v-if="userType === '根用户'"><el-icon><Setting /></el-icon>后台管理</el-menu-item>
            <el-menu-item index="/userCenter"><el-icon><House /></el-icon>个人中心</el-menu-item>
            <el-menu-item @click="Logout"><el-icon><SwitchButton /></el-icon>退出登录</el-menu-item>
          </el-sub-menu>
          <el-menu-item index="/login" v-else><el-icon><Promotion /></el-icon>登录</el-menu-item>
        </el-menu>
      </el-affix>
    </el-header>
    <el-main>
      <slot></slot>
    </el-main>
    <el-footer>
      <el-divider>
        <el-text class="mx-1"> © 2023 AC自动集 All Rights Reserved.
          网站仅供学习交流使用</el-text>
      </el-divider>
      <el-divider border-style="dotted">
        <el-link href="https://beian.miit.gov.cn/" target="_blank">赣ICP备2022006483号</el-link>
        <span> | </span>
        <el-link href="https://www.beian.gov.cn/portal/registerSystemInfo" target="_blank">赣公网安备36011102000604号</el-link>
      </el-divider>
    </el-footer>
  </el-container>
</template>

<style scoped>
.flex-grow {
  flex-grow: 2;
}
</style>